@import "nlib/config";

.categoryAndReasonInput {
  display: flex;
  .typeSelect {
    button {
      padding-left: $contentDefaultIndent;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .typeSelectDropdownClassName {
      width: auto;
      padding: 0;
    }
  }
  &.typeSelector {
    .input {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
