@import "nlib/config";

$buttonDefaultBgColor: #e9ecef;

.document {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 138px;
  margin-right: $contentDefaultIndent * 2;
  .attachment {
    flex: none;
    width: 100%;
    height: 2rem;
    overflow: hidden;
    border-radius: $uiBorderRadius;
    color: $uiFontColor;
    &:not([disabled]) {
      border-color: $uiMediumDarkColor;
      &:hover {
        background-color: darken($buttonDefaultBgColor, 5%);
      }
    }
    .content {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 500;
      span {
        margin-left: 8px;
      }
      svg {
        margin: 0 0 0 4px;
        font-size: 1rem;
      }
      .preloader {
        margin: 0;
        font-size: 1rem;
      }
    }
  }
  .preview {
    width: 2rem;
    min-width: 2rem;
    height: 2.6rem;
    margin-top: -0.3rem;
    margin-bottom: -0.3rem;
    overflow: hidden;
  }
  .attachedDocumentActions {
    flex: 1;
    justify-content: flex-start;
    button,
    a {
      flex: none;
      width: fit-content;
      height: fit-content;
      margin-left: $contentDefaultIndent * 1.5 !important;
      padding: 0;
      border: none;
      background: none !important;
      box-shadow: none !important;
      font-size: 0.75rem;
      font-weight: 500;
      text-decoration: underline;
      svg {
        display: none;
      }
      &:hover {
        filter: contrast(1);
        text-decoration: none;
      }
    }
    a {
      color: $uiPrimaryColor !important;
    }
  }
  [disabled] {
    opacity: 1 !important;
  }
}
