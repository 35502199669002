@import "nlib/config";

.additionalInfo {
  flex: 14;
  width: 14px;
  max-width: 20rem;
  font-weight: 500;
  .description {
    max-width: 100%;
    &:not(button) {
      margin-left: 8px;
    }
    svg {
      font-size: 1rem;
    }
    a {
      color: inherit;
      font-weight: 500;
    }
    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .negative {
    color: $uiNegativeColor;
  }
  .highlight {
    color: $uiHighlightColor;
  }
  .positive {
    color: $uiPositiveColor;
  }
  button {
    svg {
      color: $uiHighlightColor;
    }
    .caret {
      margin-right: -$contentDefaultIndent / 2;
      color: $uiDarkColor;
      font-size: 14px;
    }
  }
}
