.category {
  .setByClient {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    overflow: visible;
    text-align: center;
    svg {
      pointer-events: none;
    }
  }
}
