@import "nlib/config";

.transactionCell {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  .dateTime {
    display: flex;
    align-items: center;
    margin-top: 1.1875rem;
    margin-bottom: $contentDefaultIndent;
    color: $uiDarkColor;
    font-size: 0.875rem;
    font-weight: 500;
    .date {
      margin-left: $contentDefaultIndent * 0.75;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .statusIcon {
      display: flex;
      align-items: center;
      margin-left: 0.3em;
      svg {
        font-size: 1.25rem;
      }
      &[data-status=toExtract] {
        svg {
          color: $uiWarningColor;
        }
      }
      &[data-status=toReconcile] {
        svg {
          color: $uiSpecialColor;
        }
      }
      &[data-status=needReaction] {
        svg {
          color: $uiWarningColor;
        }
      }
      &[data-status=toReview] {
        svg {
          color: $uiHighlightColor;
        }
      }
      &[data-status=toReport] {
        svg {
          color: $uiPositiveColor;
        }
      }
      &[data-status=exported] {
        svg {
          color: $uiMediumDarkColor;
        }
      }
      &[data-status=excluded] {
        svg {
          color: $uiAccentColor;
        }
      }
      &[data-status=error] {
        svg {
          color: $uiNegativeColor;
        }
      }
    }
  }
  .account {
    max-width: 100%;
    height: 20px;
    margin-top: auto;
    margin-bottom: $contentDefaultIndent * 1.25;
    margin-left: $contentDefaultIndent * 0.75;
    font-size: 0.75rem;
  }
  .badge {
    $colorLightening: 10%;
    width: fit-content;
    margin-top: 1px;
    white-space: nowrap;
    &[data-type=expense],
    &[data-type=purchase],
    &[data-type=spent],
    &[data-type=check],
    &[data-type=creditCardCharge] {
      background-color: mix($uiNegativeColor, $uiWhiteColor, $colorLightening);
      color: $uiNegativeColor;
    }
    &[data-type=income],
    &[data-type=received],
    &[data-type=deposit],
    &[data-type=salesReceipt] {
      background-color: mix($uiPositiveColor, $uiWhiteColor, $colorLightening);
      color: $uiPositiveColor;
    }
    &[data-type=transfer] {
      background-color: mix($uiDarkColor, $uiWhiteColor, $colorLightening);
      color: $uiDarkColor;
    }
    &[data-type=refundReceipt],
    &[data-type=creditCardCredit] {
      background-color: mix($uiWarningColor, $uiWhiteColor, $colorLightening);
      color: $uiWarningColor;
    }
    &[data-type=billPayment],
    &[data-type=receivedPayment] {
      background-color: mix($uiHighlightColor, $uiWhiteColor, $colorLightening);
      color: $uiHighlightColor;
    }
  }
  .lastExportErrors {
    color: $uiNegativeColor;
    font-weight: 500;
    svg {
      font-size: 1.2em;
    }
  }
}
