@import "nlib/config";

.extraCell {
  width: 100%;
  height: 100%;
  padding-right: $contentDefaultIndent / 2;
  .viewDetails {
    height: 1.5rem;
  }
  .upper {
    display: flex;
    align-items: center;
    margin-bottom: $contentDefaultIndent;
    .description {
      display: flex;
      position: relative;
      flex: auto;
      align-items: center;
      height: 24px;
      overflow: hidden;
      font-size: 0.75rem;
      .content {
        display: flex;
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        .text {
          padding-left: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          svg {
            font-size: 1rem;
            &.warning {
              color: $uiWarningColor;
            }
            + span {
              margin-left: 7px;
            }
          }
          &.muted {
            color: $uiPlaceholderColor;
          }
        }
      }
    }
    .badge {
      &.active {
        background: $uiLightBlueColor;
        color: $uiWhiteColor;
      }
      + .viewDetails {
        margin-left: $contentDefaultIndent;
      }
    }
  }

  .fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent;
    .dynamicBlock {
      display: flex;
      flex: 20;
      justify-content: space-between;
      width: 20px;
      margin-left: 4px;
      &[data-width=f2] {
        flex: 40;
        width: 40px;
      }
      &[data-width=f3] {
        flex: 60;
        width: 60px;
      }
      &[data-width=f4] {
        flex: 80;
        width: 80px;
      }
      &[data-width=f5] {
        flex: 100;
        width: 100px;
      }
    }
    .contact {
      flex: 20;
      width: 20px;
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .reason {
      flex: 1;
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .input {
      flex: 20;
      + .input {
        margin-left: 4px;
      }
    }
  }
  .dynamicBlock {
    .input {
      input {
        border-radius: 0;
      }
      &:last-child {
        input {
          border-top-right-radius: $uiBorderRadius;
          border-bottom-right-radius: $uiBorderRadius;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
  }
}

.commentsDropdownContent {
  min-width: 17rem !important;
  max-width: 22rem !important;
  margin: 0;
  overflow: hidden !important;
}
