@import "nlib/config";
@import "animations";

.actions {
  display: flex;
  width: fit-content;
  margin-left: auto;
  padding-left: $contentDefaultIndent * 2;
  button,
  .buttonWrap {
    position: relative;
    + button,
    + .buttonWrap {
      margin-left: $contentDefaultIndent;
    }
  }
  .tooltip {
    display: none;
    white-space: initial;
  }
  .buttonWrap:hover {
    .tooltip {
      display: flex;
      width: 14rem;
      svg {
        color: $uiWarningColor;
        font-size: 1rem;
      }
    }
  }
  .applyButton {
    min-width: 136px;
  }
  .processing {
    svg {
      animation: rotate 1s linear infinite;
    }
  }
  .moreActions {
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    svg {
      font-size: 1rem;
    }
  }
}
