@import "nlib/config";

.vendorValidation {
  position: relative;
  white-space: initial;
  .confidence {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $contentDefaultIndent $contentDefaultIndent * 2 $contentDefaultIndent / 2;
    font-weight: 500;
    .badge {
      width: 36px;
      padding: 0;
      text-align: center;
    }
  }
  .content {}
  .scroll {
    max-height: 20rem;
    overflow: auto;
  }
  .negativeText {
    color: $uiNegativeColor;
  }
  .warningText {
    color: $uiWarningColor;
  }
  .positiveText {
    color: $uiPositiveColor;
  }
  .mediumDarkText {
    color: $uiMediumDarkColor;
  }
  .description {
    padding: $contentDefaultIndent $contentDefaultIndent * 2;
    font-weight: 500;
  }
  .block {
    .title {
      padding: $contentDefaultIndent $contentDefaultIndent * 2 0;
    }
    .links {
      padding: 0 $contentDefaultIndent;
    }
  }
  .text {
    padding: 0 $contentDefaultIndent;
  }
  .link {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent * 0.75 $contentDefaultIndent;
    border-radius: $contentDefaultIndent / 2;
    font-weight: 500;
    cursor: default;
    &.clickable {
      cursor: pointer;
      .text {
        text-decoration: underline;
      }
      &:hover {
        .text {
          text-decoration: none;
        }
      }
    }
    .label {
      min-width: $contentDefaultIndent;
      height: $contentDefaultIndent;
      margin-right: 0.5em;
      border-radius: $contentDefaultIndent / 4;
      background-color: currentColor;
    }
    .text {
      flex: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .frequency {
      min-width: 36px;
      padding-right: 0;
      padding-left: 0;
      text-align: center;
    }
    &.critical {
      .text {
        color: $uiNegativeColor;
      }
    }
  }
  .ignoreValidation {
    padding: $contentDefaultIndent $contentDefaultIndent * 2;
    border-top: 1px solid $uiBorderColor;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    svg {
      font-size: 1rem;
    }
    &:hover {
      background-color: $uiBackgroundColor;
    }
  }
}
.icon {
  width: 100%;
  .badge {
    width: 100%;
    text-align: center;
  }
}
.dropDown {
  width: 100%;
  min-width: 16rem;
  margin: 0;
}
