@import "nlib/config";

.row {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid $uiBorderColor;
  &.header {
    border-top: none;
  }
  &:hover:not(.header) {
    background-color: $uiBackgroundColor;
  }
  .cell {
    flex: 1;
    padding: $contentDefaultIndent * 0.75 $contentDefaultIndent / 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:first-child {
      flex: auto;
      width: 86px;
      max-width: 86px;
      padding-left: $contentDefaultIndent;
    }
    &:nth-child(2) {
      width: 92px;
      max-width: 92px;
      padding-right: $contentDefaultIndent;
      text-align: right;
    }
    &:last-child {
      padding-right: $contentDefaultIndent;
    }
    .amount {
      font-weight: 500;
    }
    &.icon {
      flex: none;
      width: 24px;
      padding-left: 0;
      svg {
        color: $uiMediumDarkColor;
        font-size: 1rem;
      }
    }
    &.muted {
      color: $uiMediumDarkColor;
    }
  }
  .tooltip {
    width: 220px;
    > div {
      font-weight: normal !important;
    }
    div + div {
      margin-top: $contentDefaultIndent;
    }
  }
}
